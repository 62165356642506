<template>
  <div class="send-mobile-app-link-dialog">
    <v-tabs v-model="tab" height="70" show-arrows>
      <v-tab v-for="item in items" :key="item.tab">
        {{ item.tab }}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item
        v-for="item in items"
        :key="item.tab"
        :transition="false"
        :reverse-transition="false"
      >
        <v-card flat>
          <component :is="item.content" v-bind="item.props" />
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
export default {
  name: "SendMobileAppLinkDialog",
  data() {
    return {
      tab: null,
      items: [
        {
          tab: this.$trans("send_mobile_app_link_dialog_email_tab_title"),
          content: () => import("./components/SendMobileAppLinkDialogEmailTab"),
        },
        {
          tab: this.$trans("send_mobile_app_link_dialog_sms_tab_title"),
          content: () => import("./components/SendMobileAppLinkDialogSMSTab"),
        },
      ],
    };
  },
};
</script>

<style lang="scss">
.send-mobile-app-link-dialog {
  .v-tab {
    bottom: -15px;
  }
  .v-tabs-bar {
    .v-slide-group__content {
      padding-left: 50px;
      padding-right: 50px;
    }
    .v-slide-group__wrapper {
      border-bottom: 1px solid #26323831;
    }
  }
}
</style>
